import { StaticImageData } from 'next/image';
import brokenImg from './images/broken-image.png';
import v1_upload from './images/v1_upload.png';
import v1_pharmacy from './images/v1_pharmacy.png';
import v1_pending from './images/pendingIcon.png';
import v1_approve from './images/approveIcon.png';
import v1_block from './images/blockIcon.png';
import v1_health from './images/v1_health.png';
import v1_order from './images/v1_order.png';
import v1_logo from './images/v1_logo.png';
import v1_whatsapp from './images/v1_whatsapp.png';
import v1_cashback from './images/v1_cashback.png';

import v1_howToVideo from './images/v1_howToVideo.png';
import v1_google_play from './images/v1_google_play.png';
import v1_apple_store from './images/v1_apple_store.png';
import v1_refer from './images/v1_refer.png';
import v1_avatar from './images/v1_avatar.png';
import v1_paymentsIcons from './images/v1_paymentsIcons.png';
import v1_phregister from './images/v1_phregister.svg';
import v1_emptyfav from './images/v1_emptyfav.svg';
import v1_prescription1 from './images/prescription/prescription1.png';
import v1_prescription2 from './images/prescription/prescription2.png';
import v1_prescription3 from './images/prescription/prescription3.png';
import v1_prescription4 from './images/prescription/prescription4.png';
import v1_prescription5 from './images/prescription/prescription5.png';
import v1_default_medicine from './images/v1_default_medicine.png';
import v1_default_healthcare from './images/v1_default_healthcare.png';
import v1_default_food from './images/v1_default_food.png';
import v1_default_beauty from './images/v1_default_beauty.png';
import v1_default_pet_and_vet from './images/v1_default_pet_&_vet.png';
import v1_default_brand from './images/v1_default_brand.png';
import v1_broken_brand from './images/brocken_brand.png';


export const IM_LOGO: StaticImageData = v1_logo;
export const IM_BROCKEN: StaticImageData = brokenImg;
export const IM_UPLOAD: StaticImageData = v1_upload;
export const IM_PHARMACY: StaticImageData = v1_pharmacy;
export const IM_B2B_PENDING: StaticImageData = v1_pending;
export const IM_B2B_APPROVE: StaticImageData = v1_approve;
export const IM_B2B_BLOCK: StaticImageData = v1_block;
export const IM_HEALTH: StaticImageData = v1_health;
export const IM_ORDER: StaticImageData = v1_order;
export const IM_WHATSAPP: StaticImageData = v1_whatsapp;
export const IM_CASHBACK: StaticImageData = v1_cashback;

export const IM_HOW_TO_VIDEO: StaticImageData = v1_howToVideo;
export const IM_GOOGLE_PLAY: StaticImageData = v1_google_play;
export const IM_APP_STORE: StaticImageData = v1_apple_store;
export const IM_REFER: StaticImageData = v1_refer;
export const IM_AVATAR: StaticImageData = v1_avatar;
export const IM_PAYMENTS_ICONS: StaticImageData = v1_paymentsIcons;
export const IM_PH_REGISTER: StaticImageData = v1_phregister;
export const IM_EMPTY_FAV: StaticImageData = v1_emptyfav;
export const IM_BROKEN_BRAND: StaticImageData = v1_broken_brand;

export const IM_PRESCRIPTION_1: StaticImageData = v1_prescription1;
export const IM_PRESCRIPTION_2: StaticImageData = v1_prescription2;
export const IM_PRESCRIPTION_3: StaticImageData = v1_prescription3;
export const IM_PRESCRIPTION_4: StaticImageData = v1_prescription4;
export const IM_PRESCRIPTION_5: StaticImageData = v1_prescription5;

export const IM_PRODUCT_DEFAULT: {
	[key: string]: StaticImageData;
} = {
	medicine: v1_default_medicine as StaticImageData,
	healthcare: v1_default_healthcare as StaticImageData,
	food: v1_default_food as StaticImageData,
	beauty: v1_default_beauty as StaticImageData,
	pet_and_vet: v1_default_pet_and_vet as StaticImageData,
	uncategorized: brokenImg as StaticImageData
};

export const IM_BRAND_DEFAULT: StaticImageData = v1_default_brand;
