import { useState, useEffect, useCallback } from 'react';

// Custom hook for a countdown timer with control functions and a finish callback
function useTimer(initialSeconds, onFinished = null) {
	const [timeLeft, setTimeLeft] = useState(initialSeconds);
	const [isActive, setIsActive] = useState(false);

	const startTimer = useCallback(() => {
		setIsActive(true);
	}, []);

	const stopTimer = useCallback(() => {
		setIsActive(false);
	}, []);

	const resetTimer = useCallback(() => {
		setIsActive(false);
		setTimeLeft(initialSeconds);
	}, [initialSeconds]);
	const restartTimer = useCallback(() => {
		setTimeLeft(initialSeconds);
		setIsActive(true);
	}, []);

	useEffect(() => {
		let intervalId = null;

		if (isActive && timeLeft > 0) {
			intervalId = setInterval(() => {
				setTimeLeft((timeLeft) => timeLeft - 1);
			}, 1000);
		} else if (timeLeft === 0 && isActive) {
			setIsActive(false);
			if (onFinished) onFinished();
		}

		return () => clearInterval(intervalId);
	}, [isActive, timeLeft, onFinished]);

	// Calculate minutes and seconds from timeLeft
	const minutes = Math.floor(timeLeft / 60);
	const seconds = timeLeft % 60;

	return {
		minutes: String(minutes).padStart(2, '0'),
		seconds: String(seconds).padStart(2, '0'),
		isActive,
		startTimer,
		stopTimer,
		resetTimer,
		restartTimer
	};
}

export default useTimer;
